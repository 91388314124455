"use-strict";

import {Element} from "../element/element";

/**
 * Table
 */
export class Table {

    static init () {

        if (Element.TABLE_ELEMENT.length > 0) {

            Element.TABLE_ELEMENT.forEach((element) => {
                Table.initTable(element);
            });
        }
    }

    /**
     * initTable
     * @param {HTMLElement} element
     */
    static initTable(element) {
        let tables = element.querySelectorAll('table');

        if (tables.length > 0) {
            tables.forEach((table) => {
                table.classList.add('table');
                table.classList.add('table-striped');
            });
        }
    }
}