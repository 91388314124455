import {Ajax} from "../ajax/ajax";

"use-strict";

import {Element} from "../element/element";

/**
 * Forms
 */
export class Forms {

    static init() {

        if (Element.FORMS_ELEMENT.length > 0) {
            Element.FORMS_ELEMENT.forEach((form) => {
                Forms.initSubmit(form);
            });
        }
    }

    /**
     *
     * @param {HTMLElement} form
     */
    static initSubmit(form) {
        let submitButton = form.querySelector('.forms-submit-button');

        if (submitButton !== null) {
            submitButton.addEventListener('click', function (event) {
                event.preventDefault();

                let id = form.id;
                let formData = new FormData(form);
                formData.append('id', id);
                let ajax = submitButton.dataset.ajax;

                Ajax.fetchItems(formData, ajax).then(
                    (response) => {
                        return response.json();
                    }
                ).then(
                    (response) => {
                        console.log(response);
                    });
            })
        }
    }
}