import {Element} from "../element/element";

"use-strict";

/**
 * Porgressbar
 */
export class Progressbar {

    /**
     * init
     */
    static init() {
        if (Element.PROGRESSBAR_ELEMENT.length > 0) {
            Element.PROGRESSBAR_ELEMENT.forEach((element) => {
                Progressbar.calculatePercentage(element);
            });
        }
    }

    /**
     * calculatePercentage
     * @param {HTMLElement} element
     */
    static calculatePercentage(element) {
        let currentValue = parseFloat(element.dataset.current);
        let maxValue = parseFloat(element.dataset.max);
        let percentage = (currentValue / maxValue)*100;
        element.style.width = percentage + "%";
        element.innerHTML = percentage + "%";
    }

}