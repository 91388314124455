"use-strict";

/**
 * AlertBox
 */
export class Alertbox {

    /**
     *
     * @param {string} message
     */
    static init(message = 'Hello World') {
        alert(message);
    }
}