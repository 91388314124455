"use-strict";

/**
 * Element
 */
export class Element {

    /**
     *
     * @type {NodeList}
     */
    static PROGRESSBAR_ELEMENT = document.querySelectorAll('.progress-bar');

    /**
     *
     * @type {NodeList}
     */
    static TABLE_ELEMENT = document.querySelectorAll('.table-container');

    /**
     *
     * @type {NodeList}
     */
    static FORMS_ELEMENT = document.querySelectorAll('.form-plugin');

}