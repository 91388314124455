import {Alertbox} from "./components/alertbox/alertbox";
import {Progressbar} from "./components/progressbar/progressbar";
import {Table} from "./components/table/table";
import {Forms} from "./components/forms/forms";

function ready() {
    // Alertbox.init();

    // Progressbar
    Progressbar.init();

    // Table
    Table.init();

    // Forms
    Forms.init();
}

if (window.addEventListener) {
    window.addEventListener("load", ready, false);
} else if (window.attachEvent) {
    window.attachEvent("onload", ready);
} else {
    window.onload = ready;
}